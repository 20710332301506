import { Component, Prop, Vue } from 'vue-property-decorator';
import LineChart from '@/components/ui/charts/line-chart/lineChart.vue';
import { ChartData, ChartOptions } from 'chart.js';
import { COLOR_LIGHT, COLORS } from '@/constants/color.constant';
import { processHEX } from '@/helpers/color.helper';
import { numberFormatter } from '@/helpers/string.helper';
import { isMobile } from '@/helpers/app.helper';

@Component({
  name: 'TotalRevenueTacosChart',
  components: {
    LineChart,
  },
})
export default class TotalRevenueTacosChart extends Vue {
  @Prop({ required: true })
  data: any;

  @Prop({ required: false })
  height?: number;

  get chartData(): ChartData {
    return {
      labels: Object.keys(this.data).map(item => item),
      datasets: [
        {
          label: 'Total revenue, $',
          type: 'bar',
          data: Object.values(this.data).map((item: any) => item.totalSales) as any,
          borderColor: COLORS[0],
          backgroundColor: `rgba(${processHEX(COLORS[0]).join(',')}, 0.3)`,
          yAxisID: 'y1'
        },
        {
          label: 'TACoS, %',
          type: 'line',
          data: Object.values(this.data).map((item: any) => item.tacos ? (item.tacos * 100).toFixed(0) : 0) as any,
          borderColor: COLORS[1],
          backgroundColor: COLORS[1],
          yAxisID: 'y2'
        }
      ]
    };
  }

  get chartOptions(): ChartOptions {
    return {
      layout: {
        padding: {
          left: 50,
          right: 50,
          top: 25,
          bottom: 25,
        }
      },
      responsive: !isMobile(),
      animation: {
        onProgress: ({ chart }) => {
          const ctx = chart.ctx;
          ctx.textAlign = 'center';
          ctx.fillStyle = "rgba(0, 0, 0, 1)";
          ctx.textBaseline = 'bottom';

          this.chartData.datasets?.forEach((item, i) => {
            const meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              const sign = meta.yAxisID === 'y2' ? '%' : '$';
              const padding = meta.yAxisID === 'y2' ? 5 : -15;
              meta.data.forEach((bar, index) => {
                const value = meta.yAxisID === 'y2' ? item.data?.[index] : numberFormatter(item.data?.[index] as number, { fractionPartCount: 0 });
                ctx.fillText(
                  item.data?.[index] ? `${value} ${sign}` : '',
                  bar.x,
                  bar.y - padding
                );
              });
            }
          })
        }
      },
      scales: {
        x: {
          grid: {
            display: false,
            color: COLOR_LIGHT,
          },
        },
        y1: {
          grid: {
            display: false,
            color: COLOR_LIGHT,
          },
          beginAtZero: true,
          afterFit: () => 1050
        },
        y2: {
          position: 'right',
          grid: {
            display: false,
            color: COLOR_LIGHT,
          },
          beginAtZero: true,
          afterFit: () => 1050,
        }
      }
    }
  }

  get chartWidth() {
    return isMobile() ? 1200 : undefined;
  }
}
